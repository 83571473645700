import React from 'react';
import { arrayOf, string, shape } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import get from 'lodash/get';

import Link from '../../link';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const namespace = 'ui-search-header--seller';

/**
 * @typedef {Object} LogoProps
 * @property {Object} pictures - Las imágenes asociadas al logo.
 * @property {string} pictures.2x - La URL de la imagen en resolución 2x.
 */

/**
 * @typedef {Object} PublicationsFromProps
 * @property {string} text - El texto que describe las publicaciones.
 */

/**
 * @typedef {Object} ViewMoreProps
 * @property {string} target - La URL del enlace "Ver más".
 * @property {Object} label - El texto del enlace "Ver más".
 * @property {string} label.text - El texto del enlace.
 */

/**
 * @typedef {Object} DescriptionProps
 * @property {string} type - El tipo de descripción.
 * @property {string} text - El texto de la descripción.
 */

/**
 * @typedef {Object} EshopHeaderProps
 * @property {string} className - Clases CSS adicionales para el componente.
 * @property {Object} title - El título del encabezado.
 * @property {string} title.text - El texto del título.
 * @property {LogoProps} logo - Las propiedades del logo.
 * @property {PublicationsFromProps} publications_from - Información sobre las publicaciones.
 * @property {ViewMoreProps} view_more - Propiedades del enlace "Ver más".
 * @property {DescriptionProps[]} [descriptions] - Lista de descripciones opcionales.
 */

const EshopHeader = ({ className = null, title, logo, publications_from, view_more, descriptions = null }) => (
  <div className={classnames(namespace, className)}>
    <div className={`${namespace}__logo`}>
      <Image className={`${namespace}__image`} src={get(logo, 'pictures.2x')} lowEnd alt="eshops header" />
    </div>
    <div className={`${namespace}__info`}>
      <p className={`${namespace}__strapline`}>{publications_from.text}</p>
      <h1 className={`${namespace}__title`}>{title.text}</h1>
      <Link className={`${namespace}__action`} href={view_more.target} title={view_more.label.text}>
        {view_more.label.text}
      </Link>
    </div>
    {descriptions?.map(
      (description) =>
        description && (
          <p
            key={description.text}
            className={classnames(`${namespace}__description`)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description.text }}
          />
        ),
    )}
  </div>
);

EshopHeader.propTypes = {
  className: string,
  descriptions: arrayOf(
    shape({
      text: string,
    }),
  ),
  logo: shape({
    pictures: shape({
      '2x': string.isRequired,
    }),
  }).isRequired,
  publications_from: shape({
    text: string,
  }).isRequired,
  title: shape({
    text: string,
  }).isRequired,
  view_more: shape({
    text: string,
    target: string,
  }).isRequired,
};

export default withComponentHandler(EshopHeader, { componentName: 'EshopHeader' });
